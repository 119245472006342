.main{
  display: flex;
  width: 100%;
  height: 100%;
}


.list{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #000000;
  :hover{
    background-color: #0f0f0f; 
  }
  li{
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #ffffff86;
 
  }
  li a{
    display: flex;
    align-items: center;
 
    .image{
      height: 260px;
      img{
        max-width: 400px;
        max-height: 260px;
        object-fit: cover;
      }
    }

  }
  .content{
    flex: 3 1 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    color: #fff;
    h2{
      font-size: calc(1rem + 1vw);
    }
    p{
      font-size: 1rem;
    }
  }
}


@media screen and (max-width: 576px){
  .list{
    li a{
flex-direction: column;
flex: 1 0 100%;
      width: calc(100% - 1rem);
    }
  }
  
}

@media screen and (max-width: 480px){
  .list{
    li{
      flex-direction: column;
    }
    
  }
  .image  img{
    width: 100%;
  }
}