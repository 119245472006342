.moda {
  width: 100%;
  background-color: rgb(var(--background-start-rgb)); /* Utiliza el color de inicio del fondo del tema */
  border-top: 1px solid rgba(134, 134, 134, 0.555);
}

.swiper__slide {
  position: relative;
  margin-top: 2rem;
}

.slide {
  position: relative;
  img{
    width: 100%;
    height: auto;
    border: 1px solid rgba(var(--foreground-rgb), 0.56); /* Utilizza un bordo leggermente trasparente */
    object-fit: cover
  }
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(3px);
  color: white;
}

.content p {
  font-size: .9rem;
  margin-top: 10px;
}

.link {
  color: #213edd;
  text-decoration: none;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .slide {
    box-shadow: none;
  }
}


@media (max-width >= 320px) {
  .content {
    padding: 10px;
  }
}
