.main {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: rgb(var(--background-start-rgb));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.content {
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
    max-width: 1400px;
    background-color: rgb(var(--background-start-rgb));
    border-left: 1px solid rgba(220, 220, 220, 0.219);
    border-right: 1px solid rgba(220, 220, 220, 0.219);
    margin-bottom: 2rem;
}

@media screen and (max-width: 1280px) {
    .content{
        width: 100%;
    }
}

@media screen and (max-width: 968px) {
    .content{
        width: 100%;
    }
}

@media screen and (max-width: 868px) {
    .content{
        width: 100%;
    }
}


@media screen and (max-width: 768px) {
    .content{
        width: 100%;
    }
}
