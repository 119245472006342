.tecnologia {
  width: 100%;
  padding: 2rem 0;
  background-color: rgb(var(--background-start-rgb)); /* Utiliza el color de inicio del fondo del tema */
}

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 1rem;
}

.product {
  flex: 1 1 calc(25% - 1rem);
  background-color: rgb(0, 0, 0);
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.418);
}

.product.large {
  flex-basis: calc(50% - 1rem); /* Ajusta el tamaño del producto grande */
}


.product:hover {
  transform: translateY(-5px);
}

.product_image {
  width: 100%;
  overflow: hidden;

  img{
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}


.product_description {
  padding: 1rem;
}

.product_description h2 {
  font-size: 1.5rem;
  color: rgb(var(--text-h2)); /* Utiliza el color del texto */
}

.product_description p {
  color: rgb(var(--text-p)); /* Utiliza el color del texto */
}

@media screen and (max-width: 1024px) {
  .product {
    flex: 1 1 calc(33% - 1rem); /* Ajusta el tamaño de los productos */
  }

  .product.large {
    flex-basis: calc(50% - 1rem); /* Ajusta el tamaño del producto grande */
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 0.5rem;
    flex-direction: column;
  }

  .product {
    flex: 1 1 calc(50% - 1rem); 
  }

  .product.large {
    flex-basis: 100%; 
  }
}

@media screen and (max-width: 320px) {

  .product_description h2 {
    font-size: 1.2rem;
  }

  .product_description p {
    font-size: 0.9rem;
  }
}